import { archiveApplication } from 'applications/actions/archive-application';
import { createApplication } from 'applications/actions/create-application';
import { Application } from 'applications/domain/application';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { merge, assign, pick, cloneDeep } from 'lodash';
import { GetPolicyIssuingFlowStepFromStepIndex } from 'policy-issuing/policy-issuing-scene';
import { Quote } from 'policy-issuing/quotes/domain/quote';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import { ProductModuleDefinitionSchema } from 'product-modules/domain/product-module-definition-schema';
import React, { useEffect, useRef, useState } from 'react';
import { WrappedLoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import {
  FormWrapperStyle,
  getComponentsFromSchema,
  RootSchemaForm,
} from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { toInputData, toOutputData } from 'rootstrap/components-old/root-schema-form/utils/output-data';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { SteppedFullScreenModalComponentParams } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { ApiError } from 'shared/api';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { useForm } from 'shared/hooks/form';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { JSONObject } from 'shared/utils';
import { useSiteConfigContext } from 'style-context';

interface Props extends SteppedFullScreenModalComponentParams {
  setStepProgress: (stepProgress: string) => void;
  applicationSchemaFormSubmitButtonRef: any;
  applicationSchemaFormData: JSONObject | undefined;
  setApplicationSchemaFormData: (data: JSONObject | undefined) => void;
  productModuleDefinitionApplicationSchema?: ProductModuleDefinitionSchema;
  productModuleDefinitionQuoteSchema?: ProductModuleDefinitionSchema;
  quoteSchemaFormData: JSONObject | undefined;
  selectedQuote: Quote | undefined;
  policyholder?: Policyholder;
  setApplication: (application: Application | undefined) => void;
  setIsLoading: (isLoading: boolean) => void;
  setError: (error?: ApiError) => void;
  application?: Application;
  priorStepKey?: IssuingSceneStepKeys;
  productModuleDefinition: ProductModuleDefinition | undefined;
  isLoading: boolean;
  isCompleted: boolean;
  stepOrder: Record<IssuingSceneStepKeys, number>;
  isMultiQuoteStep: boolean;
  setApplicationIsValid: (applicationIsValid: boolean) => void;
}

export const CreateApplication = (params: Props) => {
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { environment, organizationId, auth, isUnauthenticated } = embedParams;
  const [applicationInputData, setApplicationInputData] = useState<JSONObject>({});
  const {
    setStepProgress,
    applicationSchemaFormData,
    applicationSchemaFormSubmitButtonRef,
    setApplicationSchemaFormData,
    productModuleDefinitionApplicationSchema,
    productModuleDefinitionQuoteSchema,
    quoteSchemaFormData,
    application,
    priorStepKey,
    setIsLoading,
    setError,
    prefillValues,
    productModuleDefinition,
    onNextCompleted,
    isLoading,
    isCompleted,
    stepOrder,
    isMultiQuoteStep,
    setApplicationIsValid,
  } = params;
  const { startedTrack } = useMixpanelTrack();

  useEffect(() => {
    startedTrack({
      stepName: MixpanelStepNames.Application,
    });
  }, []);

  const disableSteppedComponents = !!siteConfig?.styles.disableSteppedComponents;

  const clonedQuoteSchemaInputData = cloneDeep(quoteSchemaFormData);
  const clonedApplicationInputData = cloneDeep(applicationInputData);
  const quoteStepKeys = quoteSchemaFormData ? Object.keys(quoteSchemaFormData) : undefined;

  const rootSchemaFormRef = useRef<any>();

  const form = useForm<JSONObject>();
  usePromise(async () => {
    setIsLoading(true);
    if (!productModuleDefinitionApplicationSchema) {
      throw new Error('Product module definition not found');
    }

    const defaultValues = clonedQuoteSchemaInputData;
    form.reset({ ...prefillValues?.application, ...defaultValues });
    const Components = getComponentsFromSchema({
      schema: productModuleDefinitionApplicationSchema?.json,
      activeElement: {
        elementId: '',
      },
      currency: productModuleDefinition?.settings.billing.currency,
      form,
      onCompletedActiveComponentName: '',
      siteConfig,
      setActiveElement: () => undefined,
      onSubmit: () => undefined,
      defaultValues,
      isTouched: false,
      submitOnChange: false,
      disableSteppedComponents,
      secondaryStepStaticData: undefined,
      isSecondaryStep: false,
      quoteStepKeys: quoteStepKeys,
    });

    if (application) {
      await archiveApplication({
        applicationId: application.applicationId,
        auth,
        organizationId,
        environment,
        isUnauthenticated: Boolean(isUnauthenticated),
      });
      params.setApplication(undefined);
    }

    await autoSubmitApplication({
      renderComponentsLength: Components.length,
      application,
      priorStepKey,
    });
    setIsLoading(false);
  }, []);

  const onCreateApplicationClicked = async (data: JSONObject) => {
    const { setApplication } = params;
    setIsLoading(true);
    setError();
    const { error, result: application } = await executeCreateApplication.execute(data);
    if (application) {
      setApplication(application);
      setIsLoading(false);
      return onNextCompleted();
    }
    setError(error);
    setIsLoading(false);
    return error;
  };

  const executeCreateApplication = usePromiseLazy(async (data?: JSONObject) => {
    const { policyholder, selectedQuote } = params;
    const quotePackage = selectedQuote;
    if (!quotePackage) {
      throw new Error('No quote package exists');
    }

    if (!policyholder) {
      throw new Error('No policyholder exists');
    }

    const application = await createApplication({
      auth,
      quotePackageId: quotePackage.quotePackageId,
      policyholderId: policyholder.policyholderId,
      data,
      organizationId,
      environment,
    });

    return application;
  }, []);

  const autoSubmitApplication = async (params: {
    renderComponentsLength: number;
    application?: Application;
    priorStepKey?: IssuingSceneStepKeys;
  }) => {
    const { renderComponentsLength, priorStepKey } = params;
    if (renderComponentsLength === 0) {
      if (priorStepKey && stepOrder[priorStepKey] > stepOrder.application) {
        return setStepProgress(
          GetPolicyIssuingFlowStepFromStepIndex({
            step: stepOrder.application - 1,
            issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
            isMultiQuoteStep,
          }),
        );
      }
      await onCreateApplicationClicked({});
    }
  };

  useEffect(() => {
    if (productModuleDefinitionApplicationSchema) {
      const inputData = toInputData({
        schema: productModuleDefinitionApplicationSchema.json,
        context: clonedQuoteSchemaInputData,
        formData: merge(clonedQuoteSchemaInputData, {
          ...prefillValues?.application,
          ...(applicationSchemaFormData?.applicationSchemaFormData as any),
        }),
      });
      setApplicationInputData(inputData);
    }
  }, [
    productModuleDefinitionApplicationSchema,
    applicationSchemaFormData?.applicationSchemaFormData,
    quoteSchemaFormData,
    prefillValues,
  ]);

  if (!productModuleDefinitionApplicationSchema || !productModuleDefinitionApplicationSchema.json || isLoading) {
    return (
      <>
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <WrappedLoadingInputs count={3} />
        </div>
      </>
    );
  }

  return (
    <FormWrapperStyle>
      <ErrorAlert error={executeCreateApplication.error} />
      <RootSchemaForm
        ref={rootSchemaFormRef}
        secondaryStepStaticData={undefined}
        isSecondaryStep={true}
        disableSteppedComponents={!!disableSteppedComponents}
        isTouched={isCompleted}
        quoteStepKeys={quoteStepKeys}
        defaultValues={{
          ...toInputData({
            formData: { ...prefillValues?.application },
            schema: productModuleDefinitionApplicationSchema.json || [],
          }),
          ...merge(clonedQuoteSchemaInputData, clonedApplicationInputData),
        }}
        prefillValues={prefillValues?.application}
        isDisabled={isLoading}
        currency={productModuleDefinition?.settings.billing.currency}
        submitOnChange={false}
        onError={() => undefined}
        onCompletedActiveComponentName={''}
        onComponentChanged={() => setError(undefined)}
        setIsValid={(isValid) => setApplicationIsValid(isValid)}
        onSubmit={async (formData) => {
          const clonedFormData = cloneDeep(formData);
          setApplicationSchemaFormData(clonedFormData);

          const quoteOutputData = cloneDeep(
            toOutputData({
              formData,
              schema: productModuleDefinitionQuoteSchema?.json || [],
            }),
          );

          const applicationData = cloneDeep(
            toOutputData({
              formData: merge(clonedQuoteSchemaInputData, clonedFormData),
              schema: productModuleDefinitionApplicationSchema.json,
            }),
          );

          const mergeQuoteApplicationData = assign({}, quoteOutputData, applicationData);
          const data = pick(mergeQuoteApplicationData, Object.keys(applicationData));

          await onCreateApplicationClicked(data);
        }}
        // We need to have the first component in the list be displayed otherwise the stepped components have no reference for a starting point
        // If the first component is not displayed the stepped components will not work - this is to make sure that we always have the fist component displayed
        schema={productModuleDefinitionApplicationSchema.json || []}
        submitButtonRef={applicationSchemaFormSubmitButtonRef}
      />
    </FormWrapperStyle>
  );
};
